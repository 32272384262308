import React, { useState } from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  brandFonts,
  fontSize,
  visuallyHidden,
} from '../../styles';
import { Container } from '../ui';
import { DesktopItems } from './DesktopItems';
import ProductCard from '../ProductCard';
import { H1, Typography } from '../typography/Typography';

const StyledProductListing = styled.section`
  margin: 40px 0;

  ${minBreakpointQuery.mlarge`
    margin-top: 25px;
    margin-bottom: 25px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-top: 40px;
    margin-bottom: 40px;
  `}

  ${minBreakpointQuery.xxxxlarge`
    margin-top: 60px;
    margin-bottom: 60px;
  `}
`;

const StyledHeading = styled(H1)`
  ${visuallyHidden()};
`;

const StyledItemsWrapper = styled.div`
  margin-bottom: 40px;
  ${minBreakpointQuery.mlarge`
    display: flex;
    flex-direction: column-reverse;
    min-height: 700px;
    position: relative;
 ${({ reverse }) => (reverse ? 'padding-left: 40px;' : 'padding-right: 40px;')}
  `}

  ${minBreakpointQuery.large`
     ${({ reverse }) =>
       reverse ? 'padding-left: 50px;' : 'padding-right: 50px;'}
  `}
`;

const StyledText = styled(props => (
  <Typography as="p" variant="cap" {...props} />
))`
  text-transform: uppercase;

  ${maxBreakpointQuery.mlarge`
    margin-bottom: 30px;
  `}

  ${minBreakpointQuery.mlarge`
    margin-top: auto;
  `}
`;

const StyledItemsMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;

  ${minBreakpointQuery.mlarge`
     display: none;
  `}
`;

const StyledItemsMobile = styled.div`
  ${maxBreakpointQuery.mlarge`
    display: flex;
    align-items: flex-start;
    gap: 30px;
    overflow-x: auto;
    overflow-y: hidden;
    margin-right: -30px;
    margin-left: -30px;
    padding-right: 30px;
    padding-bottom: 20px;
    padding-left: 30px;
  `}

  ${maxBreakpointQuery.smedium`
    gap: 25px;
    margin-right: -25px;
    margin-left: -25px;
    padding-right: 25px;
    padding-left: 25px;
  `}

  ${minBreakpointQuery.mlarge`
     display: none;
  `}
`;

const StyledProductCardWrapper = styled.div`
  max-width: 280px;
`;

const StyledUsps = styled.ul`
  column-count: 2;
  column-gap: 25px;

  ${maxBreakpointQuery.mlarge`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.smedium`
    column-gap: 30px;
  `}

  ${minBreakpointQuery.mlarge`
    position: absolute;
   
    ${({ reverse }) => (reverse ? 'left' : 'right')}: 0;
    
    ${({ reverse }) => (reverse ? '' : 'top: 0;')}
    padding-right: 16px;
    padding-left: 16px;
    width: 700px;
    column-count: 3;
    column-gap: 140px;
    transform:  ${({ reverse }) =>
      reverse
        ? 'translateX(0) rotate(-90deg)'
        : 'translateX(100%) rotate(90deg)'};
    transform-origin: top left;
  `}
`;

const StyledUsp = styled.li`
  font-family: ${brandFonts.tertiary};
  ${fontSize(12)};
  letter-spacing: 0.02em;
  text-transform: uppercase;

  ${minBreakpointQuery.large`
    ${fontSize(13)};
  `}
`;

const ProductListing = ({ heading, text, items, defaultImage, usps }) => {
  const [visibleMuscatel, setVisibleMuscatel] = useState();

  const [visibleChardonnay, setVisibleChardonnay] = useState();

  const { chardonnays, muscatels } = items.reduce(
    (acc, item) => {
      if (
        item.productType === 'Chardonnay' ||
        item.heading.includes('Chardonnay')
      ) {
        acc.chardonnays.push(item);
      } else if (
        item.productType === 'Muscatel' ||
        item.heading.includes('Muscatel')
      ) {
        acc.muscatels.push(item);
      }
      return acc;
    },
    {
      chardonnays: [],
      muscatels: [],
    }
  );

  return (
    <StyledProductListing>
      <Container>
        <StyledHeading>{heading}</StyledHeading>
        <StyledItemsWrapper>
          <StyledText>{text}</StyledText>
          <DesktopItems
            items={muscatels}
            defaultImage={defaultImage}
            appearThreshold={0.001}
            visibleImage={visibleMuscatel}
            setVisibleImage={setVisibleMuscatel}
          />
          <StyledItemsMobileWrapper>
            <H1>Muscatels</H1>
            <StyledItemsMobile>
              {muscatels.map((item, id) => (
                <StyledProductCardWrapper>
                  <ProductCard
                    key={id}
                    {...item}
                    appearThreshold={0.001}
                    visibleImage={visibleMuscatel}
                    setVisibleImage={setVisibleMuscatel}
                    isProductListing
                  />
                </StyledProductCardWrapper>
              ))}
            </StyledItemsMobile>
          </StyledItemsMobileWrapper>
          <StyledUsps>
            {JSON.parse(usps).map((usp, id) => (
              <StyledUsp key={id}>{usp}</StyledUsp>
            ))}
          </StyledUsps>
        </StyledItemsWrapper>
        <StyledItemsWrapper reverse>
          <DesktopItems
            items={chardonnays}
            defaultImage={defaultImage}
            appearThreshold={0.001}
            visibleImage={visibleChardonnay}
            setVisibleImage={setVisibleChardonnay}
            reverse
          />
          <StyledItemsMobileWrapper>
            <H1>Chardonnays</H1>
            <StyledItemsMobile>
              {chardonnays.map((item, id) => (
                <StyledProductCardWrapper>
                  <ProductCard
                    key={id}
                    {...item}
                    appearThreshold={0.001}
                    visibleImage={visibleChardonnay}
                    setVisibleImage={setVisibleChardonnay}
                    isProductListing
                  />
                </StyledProductCardWrapper>
              ))}
            </StyledItemsMobile>
          </StyledItemsMobileWrapper>
          <StyledUsps reverse>
            {JSON.parse(usps).map((usp, id) => (
              <StyledUsp key={id}>{usp}</StyledUsp>
            ))}
          </StyledUsps>
        </StyledItemsWrapper>
      </Container>
    </StyledProductListing>
  );
};

export default ProductListing;
