import React from 'react';
import { navigate, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  brandFonts,
  fontSize,
  fluidFontSize,
  standardTransition,
} from '../styles';
import { AnimatedBlock, AnimatedWords, AnimatedMedia, Link } from './ui';
import ProductPrice from './ProductPrice';
import { buildUrl, isOnSale, calcDiscount } from '../utils';
import { Typography } from './typography/Typography';

// moved up for hover
const StyledMedia = styled.div`
  position: relative;
  transition: ${standardTransition('transform')};

  ${({ isProductListing, isVisibleImage }) => {
    if (isProductListing) {
      return css`
        ${minBreakpointQuery.mlarge`
          position: absolute;
          top: 0;
          right: 0;
          opacity: ${isVisibleImage ? '1' : '0'};
          transition: ${standardTransition('opacity')};
        `}
      `;
    }
  }}
`;

const StyledProductCard = styled.article`
  display: flex;
  flex-direction: column-reverse;

  ${({ isProductListing }) => {
    if (isProductListing) {
      return css`
        ${maxBreakpointQuery.mlarge`
          min-width: 280px;
        `}

        ${minBreakpointQuery.mlarge`
          margin-top: 40px;
          margin-bottom: 40px;
        `}

        ${minBreakpointQuery.large`
          margin-top: 50px;
          margin-bottom: 50px;
        `}

        ${minBreakpointQuery.xlarge`
          margin-top: 60px;
          margin-bottom: 60px;
        `}
      `;
    } else {
      return css`
        &:hover {
          ${StyledMedia} {
            transform: scale(1.1);
          }
        }
      `;
    }
  }}

  ${({ isComingSoon }) => {
    if (!isComingSoon) {
      return css`
        cursor: pointer;
      `;
    }
  }}
`;

// moved up for hover
const StyledLink = styled(Link)`
  ${({ isProductListing }) => {
    if (isProductListing) {
      return css`
        ${minBreakpointQuery.mlarge`
          position: relative;
          overflow: hidden;
          transition: ${standardTransition('color')};
        `}

        &:after {
          ${minBreakpointQuery.mlarge`
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: ${brandColours.secondary};
            transform: translateX(-101%);
            transition: ${standardTransition('transform')};
          `}
        }
      `;
    }
  }}
`;

const StyledContent = styled.div`
  margin-top: 16px;

  ${minBreakpointQuery.medium`
    margin-top: 24px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 32px;
  `}

  ${({ isProductListing }) => {
    if (isProductListing) {
      return css`
        ${minBreakpointQuery.mlarge`
          display: inline-block;
          margin-top: 0;
        `}

        &:hover {
          ${StyledLink} {
            ${minBreakpointQuery.mlarge`
              color: ${brandColours.secondary};
            `}

            &:after {
              ${minBreakpointQuery.mlarge`
                transform: translateX(0);
              `}
            }
          }
        }
      `;
    }
  }}

  ${({ isProductListing, isComingSoon }) => {
    if (isProductListing && isComingSoon) {
      return css`
        ${minBreakpointQuery.mlarge`
          opacity: 0.2;
        `}
      `;
    }
  }}
`;

const StyledText = styled(props => (
  <Typography {...props} as="p" variant="H6" />
))`
  text-transform: uppercase;
`;

const StyledPrice = styled(ProductPrice)`
  ${fluidFontSize(
    '15px',
    '20px',
    breakpointSizes.tiny,
    breakpointSizes.xxlarge
  )};
`;

const StyledHeading = styled(props => (
  <Typography as="h2" variant="H4" {...props} />
))`
  margin-top: 12px;
  text-transform: uppercase;
  word-spacing: 100vw;

  ${minBreakpointQuery.medium`
    margin-top: 18px;
    word-spacing: normal;
  `}

  ${minBreakpointQuery.large`
    margin-top: 24px;
  `}
`;

const StyledDescription = styled.p`
  margin-top: 8px;
  ${fontSize(14)};
  line-height: 1.4;

  ${minBreakpointQuery.medium`
    margin-top: 12px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 16px;
    ${fontSize(15)};
  `}
`;

const StyledMediaWrapper = styled.div`
  overflow: hidden;
`;

const StyledImageWrapper = styled(AnimatedMedia)`
  max-width: 550px;
`;

const StyledImage = styled(GatsbyImage)``;

const StyledDiscount = styled.span`
  position: absolute;
  top: 18px;
  left: 18px;
  padding: 10px;
  width: 88px;
  color: ${brandColours.septenary};
  font-family: ${brandFonts.tertiary};
  ${fontSize(16)};
  line-height: 1;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: center;
  background-color: ${standardColours.white};

  ${minBreakpointQuery.medium`
    width: 98px;
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    width: 108px;
    ${fontSize(20)};
  `}
`;

const ProductCard = ({
  heading,
  slug,
  featuredImage,
  description,
  priceRange,
  variants,
  isProductListing,
  visibleImage,
  setVisibleImage,
  appearThreshold,
  ...props
}) => {
  const isComingSoon = !slug || !variants || !priceRange;

  const urlPath = !isComingSoon && `shop/${slug}`;

  return (
    <StyledProductCard
      isProductListing={isProductListing}
      isComingSoon={isComingSoon}
      onClick={() => urlPath && navigate(buildUrl(urlPath))}
      {...props}
    >
      <StyledContent
        isProductListing={isProductListing}
        isComingSoon={isComingSoon}
        onMouseOver={() => setVisibleImage && setVisibleImage(featuredImage)}
      >
        <AnimatedBlock>
          {isComingSoon ? (
            <StyledText>Coming Soon</StyledText>
          ) : (
            <StyledPrice priceRange={priceRange} variants={variants} />
          )}
        </AnimatedBlock>
        <StyledHeading variant={isProductListing ? 'H3' : 'H4'}>
          {urlPath ? (
            <StyledLink to={urlPath} isProductListing={isProductListing}>
              <AnimatedWords>{heading}</AnimatedWords>
            </StyledLink>
          ) : (
            <AnimatedWords>{heading}</AnimatedWords>
          )}
        </StyledHeading>
        {!isProductListing && description && (
          <StyledDescription>
            <AnimatedWords>{description}</AnimatedWords>
          </StyledDescription>
        )}
      </StyledContent>
      <StyledMediaWrapper>
        <StyledMedia
          isProductListing={isProductListing}
          isVisibleImage={featuredImage === visibleImage}
        >
          <StyledImageWrapper threshold={appearThreshold}>
            <StyledImage
              image={featuredImage.gatsbyImageData}
              alt={featuredImage.alt}
            />
            {!isComingSoon && isOnSale(variants) && (
              <StyledDiscount>
                {calcDiscount(priceRange, variants)}
              </StyledDiscount>
            )}
          </StyledImageWrapper>
        </StyledMedia>
      </StyledMediaWrapper>
    </StyledProductCard>
  );
};

export default ProductCard;

export const ProductCardFragment = graphql`
  fragment ProductCardFragment on ShopifyProduct {
    heading: title
    slug: handle
    featuredImage {
      gatsbyImageData(width: 550, height: 700)
      alt: altText
    }
    status
    description
    priceRange: priceRangeV2 {
      minVariantPrice {
        amount
      }
      maxVariantPrice {
        amount
      }
    }
    variants {
      storefrontId
      price
      compareAtPrice
    }
  }
`;
