import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  standardColours,
  brandColours,
  brandFonts,
  fontSize,
  fluidFontSize,
  standardTransition,
} from '../../styles';
import { AnimatedBlock, AnimatedWords, AnimatedMedia, Link } from '../ui';
import ProductPrice from '../ProductPrice';
import { isOnSale, calcDiscount } from '../../utils';
import { Typography } from '../typography/Typography';

// moved up for hover
const StyledMedia = styled.div`
  transition: ${standardTransition('transform')};

  ${({ isVisibleImage }) => {
    return css`
      ${minBreakpointQuery.mlarge`
          position: absolute;
          top: 0;
          right: 0;
          opacity: ${isVisibleImage ? '1' : '0'};
          transition: ${standardTransition('opacity')};
        `}
    `;
  }}
`;

const StyledItems = styled.article`
  display: none;

  ${minBreakpointQuery.mlarge`
          display: flex;
          flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
          justify-content: space-between;
          height: 700px;
          gap: 40px;
        `}
`;

// moved up for hover
const StyledLink = styled(Link)`
  ${minBreakpointQuery.mlarge`
          position: relative;
          overflow: hidden;
          transition: ${standardTransition('color')};
        `}

  &:after {
    ${minBreakpointQuery.mlarge`
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: 100%;
            background-color: ${brandColours.secondary};
            transform: translateX(-101%);
            transition: ${standardTransition('transform')};
          `}
  }
`;

const StyledContent = styled.div`
  &:hover {
    ${StyledLink} {
      ${minBreakpointQuery.mlarge`
              color: ${brandColours.secondary};
            `}

      &:after {
        ${minBreakpointQuery.mlarge`
                transform: translateX(0);
              `}
      }
    }
  }

  ${({ isComingSoon }) => {
    if (isComingSoon) {
      return css`
        ${minBreakpointQuery.mlarge`
          opacity: 0.2;
        `}
      `;
    }
  }}
`;

const StyledText = styled(props => (
  <Typography {...props} as="p" variant="H6" />
))`
  text-transform: uppercase;
`;

const StyledPrice = styled(ProductPrice)`
  ${fluidFontSize(
    '15px',
    '20px',
    breakpointSizes.tiny,
    breakpointSizes.xxlarge
  )};
`;

const StyledHeading = styled(props => (
  <Typography as="h2" variant="H4" {...props} />
))`
  margin-top: 12px;
  text-transform: uppercase;

  ${minBreakpointQuery.medium`
    margin-top: 18px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 24px;
  `}
`;

const StyledMediaWrapper = styled.div`
  overflow: hidden;
  position: relative;
  max-width: 550px;
  width: 100%;
`;

const StyledImageWrapper = styled(AnimatedMedia)``;

const StyledImage = styled(GatsbyImage)``;

const StyledDiscount = styled.span`
  position: absolute;
  top: 18px;
  left: 18px;
  padding: 10px;
  width: 88px;
  color: ${brandColours.septenary};
  font-family: ${brandFonts.tertiary};
  ${fontSize(16)};
  line-height: 1;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  text-align: center;
  background-color: ${standardColours.white};

  ${minBreakpointQuery.medium`
    width: 98px;
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    width: 108px;
    ${fontSize(20)};
  `}
`;

const StyledContentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  gap: 40px;
`;

export function DesktopItems({
  visibleImage,
  setVisibleImage,
  appearThreshold,
  items,
  defaultImage,
  reverse,
  ...props
}) {
  return (
    <StyledItems reverse={reverse} {...props}>
      <StyledContentsWrapper>
        {items.map(({ heading, featuredImage, variants, priceRange, slug }) => {
          const isComingSoon = !slug || !variants || !priceRange;
          const urlPath = !isComingSoon && `shop/${slug}`;
          return (
            <StyledContent
              isComingSoon={isComingSoon}
              onMouseOver={() =>
                setVisibleImage && setVisibleImage(featuredImage)
              }
            >
              <AnimatedBlock>
                {isComingSoon ? (
                  <StyledText>Coming Soon</StyledText>
                ) : (
                  <StyledPrice priceRange={priceRange} variants={variants} />
                )}
              </AnimatedBlock>
              <StyledHeading variant={'H3'}>
                {urlPath ? (
                  <StyledLink to={urlPath}>
                    <AnimatedWords>{heading}</AnimatedWords>
                  </StyledLink>
                ) : (
                  <AnimatedWords>{heading}</AnimatedWords>
                )}
              </StyledHeading>
            </StyledContent>
          );
        })}
      </StyledContentsWrapper>
      <StyledMediaWrapper>
        <StyledMedia isVisibleImage={!visibleImage}>
          <StyledImageWrapper threshold={appearThreshold}>
            <StyledImage
              image={defaultImage.gatsbyImageData}
              alt={defaultImage.alt}
            />
          </StyledImageWrapper>
        </StyledMedia>
        {items.map(({ featuredImage, variants, priceRange, slug }) => {
          const isComingSoon = !slug;

          return (
            <StyledMedia isVisibleImage={featuredImage === visibleImage}>
              <StyledImageWrapper threshold={appearThreshold}>
                <StyledImage
                  image={featuredImage.gatsbyImageData}
                  alt={featuredImage.alt}
                />
                {!isComingSoon && isOnSale(variants) && (
                  <StyledDiscount>
                    {calcDiscount(priceRange, variants)}
                  </StyledDiscount>
                )}
              </StyledImageWrapper>
            </StyledMedia>
          );
        })}
      </StyledMediaWrapper>
    </StyledItems>
  );
}
