import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import ProductListing from '../components/ProductListing/ProductListing';

const ProductListingPageTemplate = ({
  data: {
    datoCmsProductListing: {
      seoMetaTags,
      title,
      text,
      products,
      defaultImage,
      usps,
    },
    allShopifyProduct,
  },
}) => {
  const items = products.map(product => {
    const shopifyProduct = allShopifyProduct.nodes.find(
      ({ slug }) => slug === product.slug
    );

    return { ...shopifyProduct, ...product };
  });

  return (
    <Layout seo={seoMetaTags} isProductListing={true}>
      <main>
        <ProductListing
          heading={title}
          text={text}
          items={items}
          defaultImage={defaultImage}
          usps={usps}
        />
      </main>
    </Layout>
  );
};

export const ProductListingPageTemplateQuery = graphql`
  query ProductListingPageTemplateQuery($shopifyProducts: [String]!) {
    datoCmsProductListing {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      text
      products {
        heading: title
        slug: shopifyProduct
        featuredImage {
          gatsbyImageData(width: 550, height: 700)
          alt
        }
      }
      defaultImage {
        gatsbyImageData(width: 550, height: 700)
        alt
      }
      usps
    }
    allShopifyProduct(filter: { handle: { in: $shopifyProducts } }) {
      nodes {
        ...ProductCardFragment
      }
    }
  }
`;

export default ProductListingPageTemplate;
