import React from 'react';
import styled, { css } from 'styled-components';
import { minBreakpointQuery, brandColours, visuallyHidden } from '../styles';
import { isOnSale, formatPrice } from '../utils';
import { Typography } from './typography/Typography';

const StyledPrice = styled(props => (
  <Typography variant="eyebrow" as="span" {...props} />
))`
  display: block;
  text-transform: uppercase;
`;

const StyledOldPrice = styled.s`
  margin-right: 18px;

  ${minBreakpointQuery.tiny`
    margin-right: 20px;
  `}

  ${minBreakpointQuery.small`
    margin-right: 22px;
  `}

  ${minBreakpointQuery.medium`
    margin-right: 24px;
  `}

  ${minBreakpointQuery.large`
    margin-right: 26px;
  `}
`;

const StyledLabel = styled.span`
  ${visuallyHidden()};
`;

const StyledAmount = styled.span`
  ${({ hasSalePrice }) => {
    if (hasSalePrice) {
      return css`
        color: ${brandColours.septenary};
      `;
    }
  }}
`;

const ProductPrice = ({
  priceRange: { minVariantPrice, maxVariantPrice },
  variants,
  ...props
}) => {
  const hasSalePrice = isOnSale(variants);

  let lowestCompareAtPrice;

  if (hasSalePrice) {
    const variantsOnSale = variants.filter(
      variant =>
        variant.compareAtPrice !== null &&
        parseFloat(variant.price) < parseFloat(variant.compareAtPrice)
    );

    if (variantsOnSale.length > 1) {
      variantsOnSale.sort(
        (a, b) => parseFloat(a.compareAtPrice) - parseFloat(b.compareAtPrice)
      );
    }

    lowestCompareAtPrice = variantsOnSale[0].compareAtPrice;
  }

  return (
    <StyledPrice {...props}>
      {hasSalePrice && (
        <>
          <StyledOldPrice>{formatPrice(lowestCompareAtPrice)}</StyledOldPrice>{' '}
        </>
      )}
      <StyledLabel>{hasSalePrice ? 'Sale price' : 'Regular price'}</StyledLabel>{' '}
      {minVariantPrice.amount !== maxVariantPrice.amount ? 'From ' : ''}
      <StyledAmount hasSalePrice={hasSalePrice}>
        {formatPrice(minVariantPrice.amount)}
      </StyledAmount>
    </StyledPrice>
  );
};

export default ProductPrice;
