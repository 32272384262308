export const isOnSale = variants =>
  !!variants?.find(
    ({ compareAtPrice, price }) =>
      compareAtPrice !== null && parseFloat(price) < parseFloat(compareAtPrice)
  );

export const calcDiscount = (priceRange, variants) => {
  const variantsOnSale = variants.filter(
    variant =>
      variant.compareAtPrice !== null &&
      parseFloat(variant.price) < parseFloat(variant.compareAtPrice)
  );

  if (variantsOnSale.length > 1) {
    variantsOnSale.sort(
      (a, b) => parseFloat(a.compareAtPrice) - parseFloat(b.compareAtPrice)
    );
  }

  const lowestCompareAtPrice = variantsOnSale[0].compareAtPrice;

  const discount =
    ((lowestCompareAtPrice - priceRange.minVariantPrice.amount) /
      lowestCompareAtPrice) *
    100;

  return `- ${parseInt(discount)}%`;
};
